import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Customer } from '../../shared/model/customer';
import { Status, UserData } from '../../shared/model/user-data';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private url: string = environment.backend.url + '/users';

  constructor(private http: HttpClient) {}

  public getAllCompanyUsers(): Observable<UserData[]> {
    return this.http.get<UserData[]>(this.url).pipe(
      map(users =>
        users.map(user => {
          return { ...user, status: Status.ACTIVE } as UserData;
        })
      )
    );
  }

  public getAllInvitedCompanyUsers(): Observable<UserData[]> {
    return this.http.get<UserData[]>(`${this.url}/invitees`).pipe(
      map(users =>
        users.map(user => {
          return { ...user, status: Status.INVITED } as UserData;
        })
      )
    );
  }

  public getInvitedToCustomer(): Observable<Customer | undefined> {
    return this.http.get<Customer | undefined>(`${this.url}/invitations`, {});
  }

  public inviteUser(email: string): Observable<void> {
    return this.http.post<void>(`${this.url}/${email}/invite`, {});
  }

  public acceptInvitation(migrateCustomerData: boolean): Observable<void> {
    return this.http.post<void>(`${this.url}/accept-invitation`, null, {
      params: {
        migrateCustomerData: migrateCustomerData,
      },
    });
  }

  public rejectInvitation(): Observable<void> {
    return this.http.post<void>(`${this.url}/reject-invitation`, {});
  }

  public canMigrateCustomerData(): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/can-migrate-customer`, {});
  }

  public updateProfile(): Observable<void> {
    return this.http.post<void>(`${this.url}/update-profile`, {});
  }

  public updatePassword(): Observable<void> {
    return this.http.post<void>(`${this.url}/update-password`, {});
  }

  public isSubscribedForMarketingMails(): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/marketing-mail-subscription`, {});
  }

  public setSubsciptionValueForMarketingMails(isSubscribedForMarketingMails: boolean): Observable<void> {
    return this.http.put<void>(`${this.url}/update-marketing-mail-subscription`, null, {
      params: {
        isSubscribedForMarketingMails: isSubscribedForMarketingMails,
      },
    });
  }
}
