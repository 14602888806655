import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS, MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LuxonModule } from 'luxon-angular';
import { NgxMaskModule } from 'ngx-mask';
import { CoreModule } from '../core/core.module';
import { CheckIconCellRendererComponent } from './components/check-icon-cell-renderer.component';
import { ChipsAutocompleteComponent } from './components/chips-autocomplete/chips-autocomplete.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CountryFlagComponent } from './components/country-flag/country-flag.component';
import { DeleteAccountComponent } from './components/delete-account/delete-account.component';
import { EntityDetailsDlgComponent } from './components/entity-details-dlg/entity-details-dlg.component';
import { FooterComponent } from './components/footer/footer.component';
import { GoogleMapsAutocompleteComponent } from './components/google-maps-autocomplete/google-maps-autocomplete.component';
import { IconSnackbarComponent } from './components/icon-snackbar/icon-snackbar.component';
import { ImageCellRendererComponent } from './components/image-cell-renderer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { CustomerRegistrationComponent } from './customer/customer-registration/customer-registration.component';
import { DirectDebitApplicationComponent } from './customer/direct-debit-application/direct-debit-application.component';
import { CustomLuxonDateAdapter } from './misc/custom-luxon-date-adapter';
import { CustomPercentPipe } from './pipes/custom-percent.pipe';
import { DateRangePipe } from './pipes/date-range.pipe';
import { VoucherCodePipe } from './pipes/voucher-code.pipe';
import { RelativeDateWithTimePipe } from './pipes/relative-date-with-time.pipe';
import { RxStompService } from './chat/rx-stomp.service';
import { rxStompServiceFactory } from './chat/rx-stomp-service-factory';
import { ChatConversationComponent } from './chat/chat-conversation/chat-conversation.component';
import { LinkyModule } from 'ngx-linky';
import { TrustpilotWidgetComponent } from './components/trustpilot-widget/trustpilot-widget.component';
import { TrustpilotMobileWidgetComponent } from './components/trustpilot-mobile-widget/trustpilot-mobile-widget.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  declarations: [
    IconSnackbarComponent,
    ConfirmDialogComponent,
    ToolbarComponent,
    UserMenuComponent,
    FooterComponent,
    CountryFlagComponent,
    EntityDetailsDlgComponent,
    ChipsAutocompleteComponent,
    LoaderComponent,
    GoogleMapsAutocompleteComponent,
    CheckIconCellRendererComponent,
    ImageCellRendererComponent,
    DateRangePipe,
    CustomerRegistrationComponent,
    CustomPercentPipe,
    DirectDebitApplicationComponent,
    DeleteAccountComponent,
    VoucherCodePipe,
    ChatConversationComponent,
    RelativeDateWithTimePipe,
    TrustpilotWidgetComponent,
    TrustpilotMobileWidgetComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    FlexLayoutModule,

    MatButtonModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,

    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    TranslocoModule,
    LuxonModule,
    RouterModule,
    MatCardModule,

    NgxMaskModule.forRoot(),
    LinkyModule,
    MatSlideToggleModule,
  ],
  exports: [
    CoreModule,
    CommonModule,
    TranslocoModule,
    FlexLayoutModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
    MatDatepickerModule,
    MatLuxonDateModule,
    MatMenuModule,
    MatProgressSpinnerModule,

    LuxonModule,
    ToolbarComponent,
    UserMenuComponent,
    FooterComponent,
    CountryFlagComponent,
    EntityDetailsDlgComponent,
    ChipsAutocompleteComponent,
    LoaderComponent,
    GoogleMapsAutocompleteComponent,
    CheckIconCellRendererComponent,
    DateRangePipe,
    CustomPercentPipe,
    VoucherCodePipe,
    DirectDebitApplicationComponent,
    NgxMaskModule,

    ChatConversationComponent,
    RelativeDateWithTimePipe,
  ],
  providers: [
    {
      provide: DateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
      useClass: CustomLuxonDateAdapter,
    },
    VoucherCodePipe,
    CustomPercentPipe,
    CurrencyPipe,
    RelativeDateWithTimePipe,
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
    },
  ],
})
export class SharedModule {}
