<div class="snackbar-container mat-simple-snackbar" *transloco="let t">
  <div class="icon">
    <mat-icon>{{ data.icon }}</mat-icon>
  </div>
  <span class="message">{{ data.message }}</span>
  <div class="mat-simple-snackbar-action">
    <ng-container *ngIf="data.additionalActions as actions">
      <button type="button" mat-button (click)="action.onClick()" *ngFor="let action of actions">
        {{ action.label }}
      </button>
    </ng-container>
    <button type="button" mat-button (click)="dismiss()">
      {{ t('common.close') }}
    </button>
  </div>
</div>
