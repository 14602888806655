import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private prefix = 'koch-logistikshop';
  private version = 'v2.0';

  constructor() {}

  public save(key: string, value: any): void {
    const jsonString = JSON.stringify(value);
    localStorage.setItem(`${this.prefix}_${this.version}.${key}`, jsonString);
  }

  public load(key: string): any {
    const jsonString = localStorage.getItem(`${this.prefix}_${this.version}.${key}`);
    return jsonString != null ? JSON.parse(jsonString) : null;
  }

  public remove(key: string): void {
    localStorage.removeItem(`${this.prefix}_${this.version}.${key}`);
  }
}
