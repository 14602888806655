<div class="account" fxLayout="column" *transloco="let t; read: 'account'">
  <app-toolbar fxFlex="none">
    <ng-container right>
      <app-user-menu></app-user-menu>
    </ng-container>
  </app-toolbar>

  <div fxFlex="grow" class="content-wrapper">
    <h1>{{ t('headline') }}</h1>
    <ng-container *ngIf="userProfile$ | async as userProfile">
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>{{ t('titleLabel') }}</mat-label>
          <input matInput value="{{ userProfile.title }}" [disabled]="true" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ t('firstnameLabel') }}</mat-label>
          <input matInput value="{{ userProfile.firstName }}" [disabled]="true" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ t('lastnameLabel') }}</mat-label>
          <input matInput value="{{ userProfile.lastName }}" [disabled]="true" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ t('phoneLabel') }}</mat-label>
          <input matInput value="{{ userProfile.phone }}" [disabled]="true" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ t('titleLabel') }}</mat-label>
          <input matInput value="{{ userProfile.email }}" [disabled]="true" />
        </mat-form-field>
      </div>
      <br />
      <ng-container *ngIf="!userProfile.idp || userProfile.idp.length === 0">
        <h2>{{ t('headline2') }}</h2>
        <div>
          <button mat-flat-button color="primary" class="mat-button-with-icon" (click)="updateProfile()">
            {{ t('updateProfile') }}
          </button>
          <div></div>
          <button mat-flat-button color="primary" class="mat-button-with-icon" (click)="updatePassword()">
            {{ t('updatePassword') }}
          </button>
        </div>
        <br />
        <h2>{{ t('headline3') }}</h2>
        <button mat-flat-button color="accent" class="mat-button-with-icon" (click)="deleteAccount()">
          {{ t('deleteAccount') }}
        </button>
        <br />
        <br />
        <div fxLayout="row">
          <mat-checkbox color="primary" [disabled]="isLoading" [formControl]="isSubscribedControl">{{
            t('isSubscribed')
          }}</mat-checkbox>
        </div>
      </ng-container>
      <ng-container *ngIf="userProfile.idp && userProfile.idp.length >= 0">
        <h1>{{ t('externalIdpHint', { idp: userProfile.idp }) }}</h1>
      </ng-container>
    </ng-container>
  </div>
  <br />

  <app-footer fxFlex="none"></app-footer>
</div>
