import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-check-icon-cell-renderer',
  template: `<div fxLayout fxLayoutAlign="start center" class="icon-container">
    <mat-icon *ngIf="value">check</mat-icon>
  </div>`,
  styles: ['.icon-container { height: 100%; }'],
})
export class CheckIconCellRendererComponent implements AgRendererComponent {
  public value = false;

  public agInit(params: ICellRendererParams): void {
    this.value = params.value;
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
