import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

/**
 * Formats a given number-value as percentage (e.g. 5.5 as 5,5 %).
 *
 * This pipe handles percent-values in a range from 0 to 100 instead of 0.0 - 1.0 (like the native PercentPipe).
 */
@Pipe({
  name: 'customPercent',
  pure: true,
})
export class CustomPercentPipe implements PipeTransform {
  private pipe: DecimalPipe;

  constructor(@Inject(LOCALE_ID) localeId: string) {
    this.pipe = new DecimalPipe(localeId);
  }

  public transform(value: number): string {
    return value !== null && value !== undefined ? `${this.pipe.transform(value, '1.0-2')} %` : '-';
  }
}
