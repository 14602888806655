<form class="form" *transloco="let t">
  <mat-form-field class="form-element">
    <mat-label>{{ labelText }}</mat-label>
    <mat-chip-list #chipList [formControl]="chipsControl" [required]="required">
      <mat-chip
        *ngFor="let item of currentItems$ | async"
        [selectable]="false"
        [removable]="true"
        (removed)="remove(item)"
      >
        <ng-container *ngTemplateOutlet="chipTemplate; context: item"> </ng-container>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        [placeholder]="newItemPlaceholder"
        #newItemInput
        [formControl]="itemControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        (blur)="onBlur()"
        (focus)="onFocus()"
      />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" autoActiveFirstOption>
      <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
        <ng-container *ngTemplateOutlet="optionsTemplate; context: option"> </ng-container>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="chipsControl.hasError('required')"> {{ t('common.required') }} </mat-error>
  </mat-form-field>
</form>
