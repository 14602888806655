<ng-container *ngIf="!isLoading; else loading">
  <div fxLayout="column" fxLayoutGap="15px" *transloco="let t; read: 'customer.users.invitedDialog'">
    <h2>{{ t('title') }}</h2>

    <p>{{ t('message', { company: invitedToCustomer.company }) }}</p>

    <div *ngIf="canMigrateCustomerData">
      <p>{{ t('migrationMessage1') }}<br />{{ t('migrationMessage2') }}</p>
      <div class="form-field">
        <mat-checkbox color="primary" [formControl]="shouldMigrateCustomerData">
          {{
            t('shouldMigrateCustomerData', {
              newCompany: invitedToCustomer.company,
              oldCompany: currentCustomer?.company
            })
          }}
        </mat-checkbox>
      </div>
    </div>

    <div fxLayoutGap="15px" fxLayoutAlign="end center">
      <button mat-raised-button color="primary" (click)="acceptInvitation()">{{ t('accept') }}</button>
      <button mat-raised-button (click)="rejectInvitation()">{{ t('reject') }}</button>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
