<footer
  ngClass.gt-xs="footer-fixed"
  ngClass.lt-sm="mobile-footer-fixed"
  fxLayout="row"
  fxLayout.lt-md="column"
  *transloco="let t"
>
  <div fxLayoutAlign="center center" fxHide.lt-md *ngIf="this.isInShopContext && this.isTrustpilotWidgetVisible">
    <app-trustpilot-widget class="shrink"></app-trustpilot-widget>
  </div>
  <app-trustpilot-mobile-widget
    class="shrink-mobile"
    fxHide.gt-sm
    *ngIf="this.isInShopContext && this.isTrustpilotWidgetVisible"
  ></app-trustpilot-mobile-widget>
  <div
    fxLayout="row"
    fxLayout.lt-lg="column"
    fxLayoutAlign.gt-sm="space-around center"
    fxFlex.gt-md="70"
    fxLayoutGap.lt-lg="-5px"
  >
    <div fxLayoutAlign="center center" ngClass.lt-sm="koch-international">
      <a class="link" ngClass.lt-lg="mobile-link" mat-button href="{{ kochInternationalWebsiteUrl }}" target="_blank">
        {{ t('footer.company') }}
      </a>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap.gt-xs="15px" ngClass.lt-sm="links">
      <button class="link" ngClass.lt-lg="mobile-link" mat-button
              [matMenuTriggerFor]="menu">{{ t('footer.support') }}</button>
      <mat-menu #menu="matMenu" yPosition="above">
        <a class="link" ngClass.lt-lg="mobile-link" mat-menu-item href="mailto:{{ t('footer.email') }}">
          <mat-icon aria-hidden="false" aria-label="Mail">email</mat-icon>
          {{ t('footer.email') }}
        </a>
      </mat-menu>
      <button class="link" ngClass.lt-lg="mobile-link" mat-button [matMenuTriggerFor]="infoMenu">{{ t('footer.info') }}</button>
      <mat-menu #infoMenu="matMenu" yPosition="above">
        <p class="link" ngClass.lt-lg="mobile-link" mat-menu-item>
          <mat-icon aria-hidden="false" aria-label="Mail">info</mat-icon>
          {{ t('footer.version') }}: {{ version }}
        </p>
      </mat-menu>
      <a class="link" ngClass.lt-lg="mobile-link" mat-button href="{{ conditionsUrl }}"
         target="_blank">{{ t('footer.terms') }}</a>
      <a class="link" ngClass.lt-lg="mobile-link" mat-button href="{{ imprintUrl }}"
         target="_blank">{{ t('footer.imprint') }}</a>
      <a class="link" ngClass.lt-lg="mobile-link" mat-button href="{{ privacyUrl }}"
         target="_blank">{{ t('footer.privacy') }}</a>
    </div>
  </div>
</footer>
