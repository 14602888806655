import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Customer } from '../../model/customer';

@Injectable({
  providedIn: 'root',
})
export class CustomerRegistrationService {
  private customerUrl: string = environment.backend.url + '/customers';

  constructor(private http: HttpClient) {}

  public saveNewCustomer(customerData: Customer): Observable<Customer> {
    return this.http.post<Customer>(this.customerUrl, customerData);
  }

  public saveMissingCustomerData(customerData: Customer): Observable<Customer> {
    return this.http.put<Customer>(`${this.customerUrl}/${customerData.id}`, customerData);
  }
}
