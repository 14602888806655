import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { KeycloakService } from 'keycloak-angular';
import { IconSnackbarAction } from '../components/icon-snackbar/icon-snackbar.component';
import { LoggingService } from '../services/logging.service';
import { UIService } from '../services/ui.service';
import { toTranslatableCamelCase } from '../string-utils';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private uiService: UIService,
    private zone: NgZone,
    private loggingService: LoggingService,
    private router: Router,
    private keycloakService: KeycloakService
  ) {}

  public handleError(error: any): void {
    const ignoreError = this.handleErrorTypes(error);
    if (!ignoreError) {
      this.loggingService.logException(error);
    } else {
      console.error(error);
    }
  }

  /**
   * Handle the error depending on the error type
   * @param error The error thrown in the application
   * @returns true if the error should not be reported e.g. to a remote logging service.
   */
  private handleErrorTypes(error: any): boolean {
    // Unwrap promise exceptions
    while (error.rejection) {
      error = error.rejection;
    }

    switch (error.name) {
      case 'HttpErrorResponse':
        return this.handleHttpError(error);
    }

    return false;
  }

  private handleHttpError(error: any): boolean {
    let translationKey: string;
    let ignoreError = false;
    let additionalActions: IconSnackbarAction[] = [];

    if (error.status === 0) {
      translationKey = 'error.connection';
      ignoreError = true;
    } else if (error.status === 400) {
      console.log(error);
      translationKey = `error.${toTranslatableCamelCase(error.error.errorCode) || 'server'}`;
    } else if (error.status === 401) {
      translationKey = 'error.unauthorized';
      ignoreError = true;

      // provide login action in snackbar
      additionalActions = [
        {
          label: translate('common.user.login'),
          onClick: () => this.keycloakService.login(),
        },
      ];
    } else if (error.status === 403) {
      translationKey = 'error.forbidden';
      ignoreError = true;
    } else if (error.status === 404) {
      translationKey = 'error.notFound';
      ignoreError = true;
      this.zone.run(() => this.router.navigateByUrl('/'));
    } else if (error.status === 500) {
      translationKey = 'error.server';
      ignoreError = true;
    } else {
      translationKey = 'error.http';
    }

    this.showMessage(translate(translationKey), additionalActions);

    return ignoreError;
  }

  private showMessage(message: string, additionalActions: IconSnackbarAction[]): void {
    // run in zone; otherwise the dismiss action will not be detected
    this.zone.run(() => {
      this.uiService.showErrorMessage(message, additionalActions);
    });
  }
}
