<mat-autocomplete
  #auto="matAutocomplete"
  autoActiveFirstOption
  (optionSelected)="selectLocation($event.option.value)"
  [displayWith]="getDisplayValue"
>
  <mat-option *ngFor="let option of selectOptions$ | async" [value]="option">
    <span class="material-icons-outlined">location_on</span>
    <div innerHTML="{{ option?.htmlDisplay }}"></div>
  </mat-option>
  <span class="powered-by-google"></span>
</mat-autocomplete>
