import { Component } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthenticationService } from '../core/authentication/authentication-service';
import { Roles } from '../core/authentication/roles';
import { ChatMessage } from '../shared/chat/chat-message';
import { ChatService } from '../shared/chat/chat.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.scss'],
})
export class ChatWidgetComponent {
  public chatEnabled: boolean = environment.chatEnabled;
  public widgetVisible: boolean = false;
  public dialogVisible: boolean = false;

  public lastMessages$ = new BehaviorSubject<ChatMessage[]>([]);
  public incomingAnswers$: Observable<ChatMessage[]>;
  private lastMessagesSubscription: Subscription;

  constructor(private chatService: ChatService, private authenticationService: AuthenticationService) {
    this.lastMessagesSubscription = this.chatService.createLastMessagesWatch().subscribe({
      next: messages => this.lastMessages$.next(messages),
      complete: () => this.lastMessagesSubscription.unsubscribe(),
    });

    this.incomingAnswers$ = this.chatService.createResponseWatch();

    this.authenticationService.isAuthenticated$.subscribe(isAuthenticated => {
      this.widgetVisible = !isAuthenticated || !this.authenticationService.isUserInRole(Roles.Admin);
    });
  }

  public handleQuestion(chatMessage: ChatMessage): void {
    this.chatService.sendQuestion(chatMessage.text);
  }

  public openWidget(): void {
    this.chatService.connect();
    this.chatService.requestLastMessages();
    this.dialogVisible = true;
  }

  public closeWidget(): void {
    this.dialogVisible = false;
    this.chatService.disconnect();
  }

  public restartChat(): void {
    this.chatService.restartChat();
  }
}
