import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.setDefaultFontSetClass('material-icons-outlined');
    matIconRegistry.addSvgIcon(
      'excel',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icons8-microsoft_excel.svg')
    );
    matIconRegistry.addSvgIcon('vip', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icons8-vip.svg'));
  }
}
