import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-check-icon-cell-renderer',
  template: ` <div fxLayout fxLayoutAlign="center center" class="icon-container">
    <img *ngIf="!!src" [src]="src" />
  </div>`,
  styles: ['.icon-container { height: 100%; } img { height: 80%; }'],
})
export class ImageCellRendererComponent implements AgRendererComponent {
  public src?: string;

  public agInit(params: ICellRendererParams): void {
    const iconId = params.value;
    this.src = environment.backend.url + '/public/product-icons/' + iconId;
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
