import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-country-flag',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.scss'],
})
export class CountryFlagComponent {
  @Input()
  public countryCode: string = '';

  @Input()
  public size: 'large' | 'small' = 'large';

  constructor() {}
}
