/**
 * Key: Country codes according to ISO 3166-1 alpha-2
 * (https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 *
 * Value: The minimum number of characters in the zip code of the respective country (excludes hyphens and periods).
 * According to: https://de.wikipedia.org/wiki/Liste_der_Postleitsysteme#:~:text=Liechtenstein%20(PLZ%3A%209485%20bis%209498,ist%20im%20Schweizer%20System%20enthalten.
 * And: https://www.iloxx.de/helpandcontact/export/postalcodes.aspx#:~:text=F%C3%BCr%20Lettland%20gilt%20folgendes%20PLZ,4%2Dstellig%2C%20z.B.%201073.&text=F%C3%BCr%20Libanon%20gibt%20es%20keine%20Postleitzahl
 */
export const MinimalZipCodeCharacters = new Map([
  ['AD', 5],
  ['AL', 4],
  ['AT', 4],
  ['AX', 5],
  ['BA', 5],
  ['BE', 4],
  ['BG', 4],
  ['BY', 6],
  ['CH', 4],
  ['CY', 4],
  ['CZ', 5],
  ['DE', 5],
  ['DK', 4],
  ['EE', 5],
  ['ES', 5],
  ['FI', 5],
  ['FR', 5],
  ['GB', 5],
  ['GG', 3],
  ['GR', 5],
  ['HR', 5],
  ['HU', 4],
  ['IS', 3],
  ['IT', 5],
  ['JE', 3],
  ['LI', 4],
  ['LT', 5],
  ['LU', 4],
  ['LV', 4],
  ['MC', 5],
  ['ME', 5],
  ['MK', 4],
  ['MT', 7],
  ['NL', 6],
  ['NO', 4],
  ['PL', 5],
  ['PT', 7],
  ['RO', 6],
  ['RS', 5],
  ['RU', 6],
  ['SE', 5],
  ['SI', 4],
  ['SK', 5],
  ['SM', 5],
  ['UA', 5],
  ['VA', 5],
  ['XK', 5],
  //['FO', 3], // no clear data
  //['GI', 4], // no clear data
  //['IM', 4], // no clear data
  //['MD', 4], // no clear data
  //['SJ', 4], // no clear data
]);
