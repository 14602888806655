import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrustpilotWidgetSettingsService } from '../../services/trustpilot-widget-settings.service';
import { environment } from 'src/environments/environment';
import packageJson from 'package.json'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public kochInternationalWebsiteUrl: string = environment.kochInternationalWebsite.url;
  public conditionsUrl: string = environment.kochInternationalWebsite.conditionsUrl;
  public imprintUrl: string = environment.kochInternationalWebsite.imprintUrl;
  public privacyUrl: string = environment.kochInternationalWebsite.privacyUrl;
  public isInShopContext: boolean;

  public isTrustpilotWidgetVisible = false;
  public version = packageJson.version;

  constructor(private router: Router, private trustpilotWidgetSettingsService: TrustpilotWidgetSettingsService) {
    this.isInShopContext = !this.router.url.includes('/customer/') && !this.router.url.includes('/admin/');
  }

  public ngOnInit(): void {
    this.trustpilotWidgetSettingsService.getTrustpilotWidgetSettings().subscribe({
      next: isVisible => {
        this.isTrustpilotWidgetVisible = isVisible;
      },
    });
  }
}
