<div
  id="trustbox"
  class="trustpilot-widget"
  data-locale="de-DE"
  data-template-id="53aa8807dec7e10d38f59f32"
  data-style-height="150px"
  data-style-width="100%"
  data-theme="light"
>
  <a href="{{ trustpilotReviewUrl }}" target="_blank" rel="noopener"> Trustpilot </a>
</div>
