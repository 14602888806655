<div
  id="trustbox-mobile"
  class="trustpilot-widget"
  data-locale="de-DE"
  data-template-id="5406e65db0d04a09e042d5fc"
  data-style-height="28px"
  data-style-width="100%"
  data-theme="light"
>
  <a href="{{ trustpilotReviewUrl }}" target="_blank" rel="noopener">Trustpilot</a>
</div>
