export interface UserData {
    accountId?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    status?: Status
}

export enum Status {
    ACTIVE = "ACTIVE",
    INVITED = "INVITED"
}
