import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';

export function toTranslatableCamelCase(s?: string): string | undefined {
  return s?.toLowerCase()?.replace(/([_/][a-z])/gi, (subString: string) => {
    return subString.toUpperCase().replace('_', '');
  });
}

export function robustStringToInteger(input: string): number {
  return Number.parseInt(input);
}

export function robustCurrencyInputToNumber(input: string | number, locale: string, fractionDigits?: number): number {
  let numericValue;
  if (typeof input === 'number') {
    numericValue = input;
  } else {
    const decimalSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
    const removeNumericDigits = new RegExp(`[^0-9-${decimalSeparator}]+`, 'g');
    const onlyNumericDigits = input.replace(removeNumericDigits, '');
    const withDotDecimalSeparator = onlyNumericDigits.replace(decimalSeparator, '.');
    numericValue = Number.parseFloat(withDotDecimalSeparator);
  }

  if (fractionDigits) {
    return +numericValue.toFixed(fractionDigits);
  } else {
    return numericValue;
  }
}

export function numberToString(
  content?: number,
  thousandSeparator?: boolean,
  numberFixedFractionDigits?: number,
  suffix?: string
): string {
  if (!content) {
    return '';
  }
  const numericPart = content.toLocaleString(undefined, {
    useGrouping: !!thousandSeparator,
    minimumFractionDigits: numberFixedFractionDigits,
    maximumFractionDigits: numberFixedFractionDigits,
  });
  if (!!suffix) {
    return numericPart + ' ' + suffix;
  }
  return numericPart;
}
