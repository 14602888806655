<ng-container *transloco="let tCommon; read: 'common'">
  <ng-container *transloco="let t; read: 'customer.directDebitApplication'">
    <h2 mat-dialog-title>{{ t('title') }}</h2>
    <ng-container *ngIf="!isLoading; else loading">
      <div
        mat-dialog-content
        class="dialog-content flex-form"
        [formGroup]="formGroup"
        gdColumns="repeat(6, 1fr)"
        gdGap="0px 10px"
        ngClass.lt-md="tablet"
        ngClass.lt-sm="mobile"
      >
        <div gdColumn="1/span 6">
          <p class="headline">{{ t('form.customerDataHeadline') }}</p>
        </div>

        <mat-form-field gdColumn="1/span 2" gdColumn.lt-md="1/span 6">
          <mat-label>{{ t('form.title.label') }}</mat-label>
          <mat-select formControlName="title">
            <mat-option [value]="title.Mr">{{ t('form.title.mr') }}</mat-option>
            <mat-option [value]="title.Ms">{{ t('form.title.ms') }}</mat-option>
            <mat-option [value]="title.Mx">{{ t('form.title.mx') }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field gdColumn="3/span 2" gdColumn.lt-md="1/span 6">
          <mat-label>{{ t('form.firstName') }}</mat-label>
          <input maxlength="255" matInput formControlName="firstName" required />
          <mat-error>{{ tCommon('required') }}</mat-error>
        </mat-form-field>
        <mat-form-field gdColumn="5/span 2" gdColumn.lt-md="1/span 6">
          <mat-label>{{ t('form.lastName') }}</mat-label>
          <input maxlength="255" matInput formControlName="lastName" required />
          <mat-error>{{ tCommon('required') }}</mat-error>
        </mat-form-field>

        <mat-form-field gdColumn="1/span 2" gdColumn.lt-md="1/span 4" gdColumn.lt-sm="1/span 6">
          <mat-label>{{ t('form.street') }}</mat-label>
          <input maxlength="255" matInput formControlName="street" required />
          <mat-error>{{ tCommon('required') }}</mat-error>
        </mat-form-field>

        <mat-form-field gdColumn="3" gdColumn.lt-md="5/span 2" gdColumn.lt-sm="1/span 3">
          <mat-label>{{ t('form.houseNumber') }}</mat-label>
          <input maxlength="255" matInput formControlName="houseNumber" required />
          <mat-error>{{ tCommon('required') }}</mat-error>
        </mat-form-field>

        <mat-form-field gdColumn="4" gdColumn.lt-md="1/span 2" gdColumn.lt-sm="4/span 3">
          <mat-label>{{ t('form.zipCode') }}</mat-label>
          <input maxlength="255" matInput formControlName="zipCode" required />
          <mat-error>{{ tCommon('required') }}</mat-error>
        </mat-form-field>

        <mat-form-field gdColumn="5/span 2" gdColumn.lt-md="3/span 4" gdColumn.lt-sm="1/span 6">
          <mat-label>{{ t('form.city') }}</mat-label>
          <input maxlength="255" matInput formControlName="city" required />
          <mat-error>{{ tCommon('required') }}</mat-error>
        </mat-form-field>

        <mat-form-field gdColumn="1/span 6">
          <mat-label>{{ t('form.email') }}</mat-label>
          <input maxlength="255" matInput formControlName="email" required />
          <mat-error *ngIf="formGroup.controls.email.hasError('required')">{{ tCommon('required') }}</mat-error>
          <mat-error *ngIf="formGroup.controls.email.hasError('email')">{{ tCommon('invalidEmail') }}</mat-error>
        </mat-form-field>

        <mat-form-field gdColumn="1/span 6">
          <mat-label>{{ t('form.bank') }}</mat-label>
          <input maxlength="255" matInput formControlName="bank" required />
          <mat-error>{{ tCommon('required') }}</mat-error>
        </mat-form-field>

        <mat-form-field gdColumn="1/span 4" gdColumn.lt-sm="1/span 6" class="two-line-error-form">
          <mat-label>{{ t('form.iban') }}</mat-label>
          <input maxlength="22" matInput formControlName="iban" required />
          <mat-error *ngIf="formGroup.controls.iban.hasError('required')">{{ tCommon('required') }}</mat-error>
          <mat-error *ngIf="formGroup.controls.iban.hasError('pattern')">
            {{ t('form.validation.ibanLength') }}
          </mat-error>
        </mat-form-field>
        <mat-form-field gdColumn="5/span 2" gdColumn.lt-sm="1/span 6" class="two-line-error-form">
          <mat-label>{{ t('form.bic') }}</mat-label>
          <input maxlength="11" matInput formControlName="bic" required />
          <mat-error *ngIf="formGroup.controls.bic.hasError('required')">{{ tCommon('required') }}</mat-error>
          <mat-error *ngIf="formGroup.controls.bic.hasError('pattern')">
            {{ t('form.validation.bicLength') }}
          </mat-error>
        </mat-form-field>

        <div gdColumn="1/span 6" class="form-field" fxLayout="column">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start start">
            <mat-checkbox color="primary" formControlName="authorizationAccepted"></mat-checkbox>
            <div>
              {{ t('form.authorizationAccepted') }}
            </div>
          </div>
          <mat-error
            *ngIf="formGroup.controls.authorizationAccepted.touched && formGroup.controls.authorizationAccepted.invalid"
          >
            {{ t('form.validation.authorizationAccepted') }}
          </mat-error>
        </div>

        <div gdColumn="1/span 6">
          <p>{{ t('form.hint') }}</p>
        </div>

        <div gdColumn="1/span 6">
          <p class="headline">{{ t('form.kochData.headline') }}</p>
          <p [innerHTML]="t('form.kochData.contentHtml')"></p>
        </div>
      </div>

      <div mat-dialog-actions class="dialog-actions" ngClass.lt-md="tablet" ngClass.lt-sm="mobile">
        <button mat-flat-button color="primary" (click)="sendApplication()" class="submit-button" [disabled]="this.formGroup.invalid">
          {{ t('sendApplication') }}
        </button>
        <button mat-flat-button (click)="cancel()">{{ tCommon('cancel') }}</button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
