import { Injectable } from '@angular/core';
import { RxStompService } from './rx-stomp.service';
import { map, Observable } from 'rxjs';
import { AssistanceRequest } from './assistance-request';
import { Message } from '@stomp/stompjs';
import { ChatMessage } from './chat-message';
import { AuthenticationService } from '../../core/authentication/authentication-service';
import { UserCustomerService } from '../customer/user-customer.service';
import { UserInfo } from './user-info';
import { RxStompState } from '@stomp/rx-stomp';
import { parseDateTime } from '../datetime-utils';
import { v4 as uuid } from 'uuid';
import { rxStompConfig } from './rx-stomp.config';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private currentUserInfo: UserInfo = {};

  public static readonly stompIdAttributeInLocalStorage = 'chat-stompId';

  constructor(
    private rxStompService: RxStompService,
    private authenticationService: AuthenticationService,
    private userCustomerService: UserCustomerService
  ) {
    this.authenticationService.userProfile$.subscribe(profile => {
      this.currentUserInfo = {
        ...this.currentUserInfo,
        email: profile?.email,
        title: profile?.title,
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        phone: profile?.phone,
      };
      this.updateUserInfo();
    });
    this.userCustomerService.customer$.subscribe(customer => {
      this.currentUserInfo = {
        ...this.currentUserInfo,
        customerId: customer?.id,
        customerName: customer?.company,
      };
      this.updateUserInfo();
    });

    this.rxStompService.connected$.subscribe(state => {
      if (state === RxStompState.OPEN) {
        this.updateUserInfo();
      }
    });
  }

  public connect(): void {
    let userUuid = localStorage.getItem(ChatService.stompIdAttributeInLocalStorage);
    if (!userUuid) {
      userUuid = uuid();
      localStorage.setItem(ChatService.stompIdAttributeInLocalStorage, userUuid);
    }

    if (this.authenticationService.isLoggedIn()) {
      if (this.authenticationService.isAuthenticated$.subscribe())
        this.authenticationService.getValidToken().subscribe((validToken: string | undefined) => {
          if (!userUuid || !validToken) {
            throw new Error('Could not update token');
          }
          this.connectAndActivate(userUuid, validToken);
        });
    } else {
      this.connectAndActivate(userUuid, '');
    }
  }

  private connectAndActivate(userUuid: string, token: string): void {
    let myConfig = rxStompConfig;
    myConfig.brokerURL = environment.backend.chatUrl;
    myConfig.connectHeaders = {
      user: userUuid,
      token: token,
    };

    this.rxStompService.configure(myConfig);
    this.rxStompService.stompUsername = userUuid;
    this.rxStompService.activate();
  }

  public disconnect(): void {
    this.rxStompService.deactivate();
  }

  public createResponseWatch(): Observable<ChatMessage[]> {
    return this.rxStompService
      .watch('/user/queue/answers')
      .pipe(map((message: Message) => ChatService.convertJsonChatMessages(JSON.parse(message.body))));
  }

  private static convertJsonChatMessage(jsonChatMessage: any): ChatMessage {
    return {
      ...jsonChatMessage,
      time: parseDateTime(jsonChatMessage.time),
    };
  }

  private static convertJsonChatMessages(jsonChatMessages: any): ChatMessage[] {
    return jsonChatMessages.map((jsonChatMessage: any) => ChatService.convertJsonChatMessage(jsonChatMessage));
  }

  private static convertJsonAssistanceRequest(jsonAssistanceRequest: any): AssistanceRequest {
    return {
      ...jsonAssistanceRequest,
      askingUser: {
        ...jsonAssistanceRequest.askingUser,
        disconnectedSince: parseDateTime(jsonAssistanceRequest.askingUser.disconnectedSince),
      },
      creationTime: parseDateTime(jsonAssistanceRequest.creationTime),
    };
  }
  private convertJsonAssistanceRequests(jsonAssistanceRequests: any): AssistanceRequest[] {
    return jsonAssistanceRequests.map((jsonAssistanceRequest: any) =>
      ChatService.convertJsonAssistanceRequest(jsonAssistanceRequest)
    );
  }

  public sendQuestion(text: string): void {
    this.rxStompService.publish({ destination: '/app/ask', body: text });
  }

  public createAssistanceRequestsWatch(): Observable<AssistanceRequest[]> {
    return this.rxStompService.watch('/topic/requests').pipe(
      map((message: Message) => message.body),
      map((json: string) => this.convertJsonAssistanceRequests(JSON.parse(json)))
    );
  }

  public requestLastMessages(): void {
    this.rxStompService.publish({ destination: '/app/requestLastMessages' });
  }

  public createLastMessagesWatch(): Observable<ChatMessage[]> {
    return this.rxStompService.watch('/user/queue/lastMessages').pipe(
      map((message: Message) => message.body),
      map((json: string) => ChatService.convertJsonChatMessages(JSON.parse(json)))
    );
  }

  public createChatHistoryWatch(): Observable<ChatMessage[]> {
    return this.rxStompService.watch('/user/queue/chatHistory').pipe(
      map((message: Message) => message.body),
      map((json: string) => ChatService.convertJsonChatMessages(JSON.parse(json)))
    );
  }

  public acceptRequest(request: AssistanceRequest): void {
    this.rxStompService.publish({ destination: '/app/accept', body: request.askingUser.userUuid });
  }

  public finishAssistance(request: AssistanceRequest): void {
    this.rxStompService.publish({ destination: '/app/finishAssistance', body: request.askingUser.userUuid });
  }

  public refreshAssistanceRequests(): void {
    this.rxStompService.publish({ destination: '/app/refreshRequests' });
  }

  public requestChatHistory(username: string): void {
    this.rxStompService.publish({ destination: '/app/requestChatHistory', body: username });
  }

  public sendAnswer(helpingPerson: string, messageText: string): void {
    const answer: AnswerDto = { askingPerson: helpingPerson, messageText: messageText };
    this.rxStompService.publish({ destination: '/app/answer', body: JSON.stringify(answer) });
  }

  public createQuestionsFromUserWatch(askingPerson: string): Observable<ChatMessage> {
    return this.rxStompService.watch('/user/queue/questions-from-user-' + askingPerson).pipe(
      map((message: Message) => message.body),
      map((json: string) => ChatService.convertJsonChatMessage(JSON.parse(json)))
    );
  }

  private updateUserInfo(): void {
    if (!this.rxStompService.connected()) {
      return;
    }
    const userInfoAsJson = JSON.stringify(this.currentUserInfo);
    this.rxStompService.publish({ destination: '/app/updateUserInfo', body: userInfoAsJson });
  }

  public get currentStompUser(): string {
    return this.rxStompService.stompUsername;
  }

  public restartChat(): void {
    this.rxStompService.publish({ destination: '/app/restartChat' });
  }
}

export interface AnswerDto {
  askingPerson: string;
  messageText: string;
}
