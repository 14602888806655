import { Injectable } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

/**
 * Adapter that customizes the luxon date adapter.
 */
@Injectable()
export class CustomLuxonDateAdapter extends LuxonDateAdapter {
  /**
   * Overwrites getFirstDayOfWeek since the default is 0 (Sunday).
   * @returns 1 (Monday) as the first day of week.
   */
  public getFirstDayOfWeek(): number {
    return 1;
  }
}
