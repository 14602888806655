<ng-container *ngIf="widgetVisible && chatEnabled">
  <div class="widget" *ngIf="dialogVisible">
    <div style="width: 100%; height: 100%" fxLayout="column" *transloco="let t; read: 'chat'">
      <div class="headbar">
        <h2 class="headline" fxFlex>{{ t('serviceChatHeadline') }}</h2>
        <button mat-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="restartChat()">
            <mat-icon aria-hidden="false" aria-label="Refresh">refresh</mat-icon>
            {{ t('restartChat') }}
          </button>
        </mat-menu>
        <button mat-button (click)="closeWidget()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <app-chat-conversation
        fxFlex="0 0 calc(100% - 3em)"
        [newMessages$]="incomingAnswers$"
        [messageHistory$]="lastMessages$"
        [isCustomer]="true"
        (handleMessageSent)="handleQuestion($event)"
      ></app-chat-conversation>
    </div>
  </div>
  <button mat-fab color="primary" class="button" (click)="openWidget()" *ngIf="!dialogVisible">
    <mat-icon>question_answer</mat-icon>
  </button>
</ng-container>
