import { Injectable, NgModule } from '@angular/core';
import {
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
} from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { environment } from '../../environments/environment';
import { SettingsService } from '../core/settings/settings.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  public getTranslation(lang: string): Promise<Translation> {
    return import(`../../translations/${lang}.json`).then(res => res.default);
  }
}

@NgModule({
  imports: [TranslocoMessageFormatModule.forRoot()],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useFactory: (settingsService: SettingsService) =>
        translocoConfig({
          availableLangs: settingsService.getAvailableTranslocoLangs(),
          fallbackLang: 'de',

          // Activate this option to support changing language in runtime.
          reRenderOnLangChange: false,
          prodMode: environment.production,
        }),
      deps: [SettingsService],
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
