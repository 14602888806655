<h1 mat-dialog-title>{{ title }}</h1>

<div mat-dialog-content fxLayout="row wrap">
  <p fxFlex>{{ message }}</p>
</div>

<div mat-dialog-actions class="actions" *transloco="let t">
  <button type="button" mat-flat-button [color]="color" (click)="onConfirm()">
    {{ useYesNo ? t('common.yes') : t('common.confirm') }}
  </button>
  <button type="button" mat-button (click)="onDismiss()">
    {{ useYesNo ? t('common.no') : t('common.cancel') }}
  </button>
</div>
