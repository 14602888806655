import { Address } from '../../model/address';

export function formatAddress(address: Address): string {
  const addressParts: string[] = [];

  if (!!address.street) {
    const streetParts: string[] = [address.street];
    pushIfNotEmpty(streetParts, address.houseNumber);
    addressParts.push(streetParts.join(' '));
  }
  if (!!address.zipCode || !!address.city) {
    const cityParts: string[] = [];
    pushIfNotEmpty(cityParts, address.zipCode);
    pushIfNotEmpty(cityParts, address.city);
    addressParts.push(cityParts.join(' '));
  }

  pushIfNotEmpty(addressParts, address.country);

  return addressParts.join(', ');
}

function pushIfNotEmpty(array: string[], value?: string): void {
  if (!!value) {
    array.push(value);
  }
}
