import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MAT_LEGACY_TABS_CONFIG as MAT_TABS_CONFIG } from '@angular/material/legacy-tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { Settings } from 'luxon';
import { QuillModule } from 'ngx-quill';
import { lastValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SettingsService } from './core/settings/settings.service';
import { GlobalErrorHandler } from './shared/misc/global-error-handler';
import { LocalLoggingService } from './shared/services/local-logging.service';
import { LoggingService } from './shared/services/logging.service';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { ChatWidgetComponent } from './chat-widget/chat-widget.component';
import {AccountComponent} from "./account/account.component";

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
        pkceMethod: 'S256',
        flow: 'standard',
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
    });
}

function initLocaleConfiguration(
  translocoService: TranslocoService,
  settingsService: SettingsService
): () => Promise<Translation> {
  return () => {
    const currentLocale = settingsService.getLocale();

    // init luxon
    Settings.defaultLocale = currentLocale.locale;

    // register locales for angular pipes and conversions
    registerLocaleData(currentLocale.angularLocaleModule);

    // set transloco language and preload translation
    const translocoLanguage = currentLocale.translocoLanguage;
    translocoService.setActiveLang(translocoLanguage);

    return lastValueFrom(translocoService.load(translocoLanguage));
  };
}

@NgModule({
  declarations: [AppComponent, AccountComponent, ChatWidgetComponent],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslocoRootModule,
    KeycloakAngularModule,
    QuillModule.forRoot(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: LoggingService,
      useClass: LocalLoggingService,
    },
    {
      provide: LOCALE_ID,
      useFactory: (settingsService: SettingsService) => settingsService.getLocale().locale,
      deps: [SettingsService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLocaleConfiguration,
      multi: true,
      deps: [TranslocoService, SettingsService],
    },
    { provide: APP_INITIALIZER, useFactory: initializeKeycloak, multi: true, deps: [KeycloakService] },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
