import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'voucherCode',
})
export class VoucherCodePipe implements PipeTransform {
  public transform(value?: string, ...args: unknown[]): string {
    return value ? value.substring(0, 4) + ' ' + value.substring(4, 8) + ' ' + value.substring(8, 12) : '';
  }
}
