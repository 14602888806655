<ng-container *ngIf="!isLoading; else loading">
  <ng-container *transloco="let tCommon; read: 'common'">
    <div class="wrapper" fxLayout="column" *transloco="let t; read: 'customer.registration'">
      <app-toolbar fxFlex="none">
        <ng-container right>
          <app-user-menu></app-user-menu>
        </ng-container>
      </app-toolbar>
      <div fxFlex="grow" class="content-wrapper">
        <div fxLayout="column" fxLayoutAlign="start center">
          <div
            ngClass.gt-sm="content-container"
            ngClass.lt-md="tablet-content-container"
            ngClass.lt-sm="mobile-content-container"
          >
            <div fxLayout="column" fxLayoutGap="25px">
              <div *ngIf="!customer; else update">
                <div class="headline">
                  {{ t('create.headline') }}
                </div>

                <div class="info-text">{{ t('create.info-text-1') }}<br />{{ t('create.info-text-2') }}</div>
              </div>
              <ng-template #update>
                <div class="headline">
                  {{ t('update.headline') }}
                </div>

                <div class="info-text">{{ t('update.info-text-1') }}<br />{{ t('update.info-text-2') }}</div>
              </ng-template>

              <div fxFlex="grow" [formGroup]="form" *transloco="let t; read: 'customer.customer'">
                <div class="input-box--no-validation-msg-padding">
                  <div class="flex-form" fxLayout="column">
                    <div class="flex-form" fxLayout="column">
                      <mat-form-field fxFlex>
                        <mat-label>{{ t('form.company') }}</mat-label>
                        <input
                          #company
                          maxLength="255"
                          matInput
                          formControlName="company"
                          required
                          data-crefopay-placeholder="address.name"
                          name="companyName"
                        />
                        <mat-error>{{ tCommon('required') }}</mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex>
                        <mat-label>{{ t('form.addressInfo') }}</mat-label>
                        <input #addressInfo maxLength="300" matInput formControlName="addressInfo" />
                        <mat-hint align="end">{{ addressInfo.value?.length || 0 }} / 300</mat-hint>
                      </mat-form-field>

                      <div fxLayout fxLayoutGap="25px">
                        <mat-form-field fxFlex>
                          <mat-label>{{ t('form.street') }}</mat-label>
                          <input
                            #street
                            maxLength="255"
                            matInput
                            formControlName="street"
                            required
                            data-crefopay-placeholder="address.street"
                          />
                          <mat-error>{{ tCommon('required') }}</mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="80px">
                          <mat-label>{{ t('form.houseNumber') }}</mat-label>
                          <input
                            #houseNumber
                            maxLength="10"
                            matInput
                            formControlName="houseNumber"
                            required
                            data-crefopay-placeholder="address.streetNo"
                          />
                          <mat-error>{{ tCommon('required') }}</mat-error>
                        </mat-form-field>
                      </div>

                      <div fxLayout.gt-xs="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="25px">
                        <mat-form-field fxFlex.gt-xs="75px">
                          <mat-label>{{ t('form.zipCode') }}</mat-label>
                          <input
                            #zipCode
                            data-crefopay-placeholder="address.zip"
                            maxLength="9"
                            matInput
                            formControlName="zipCode"
                            required
                          />
                          <mat-error>{{ tCommon('required') }}</mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex>
                          <mat-label>{{ t('form.city') }}</mat-label>
                          <input
                            #city
                            data-crefopay-placeholder="address.city"
                            maxLength="255"
                            matInput
                            formControlName="city"
                            required
                          />
                          <mat-error>{{ tCommon('required') }}</mat-error>
                        </mat-form-field>
                        <input style="display: none" data-crefopay-placeholder="address.country" #countryRef />
                        <mat-form-field fxFlex.gt-xs="175px">
                          <mat-label>{{ t('form.country') }}</mat-label>
                          <mat-select formControlName="countryCode" required>
                            <mat-option *ngFor="let country of countries" [value]="country.code">
                              <div class="country-flag">
                                <app-country-flag size="small" [countryCode]="country.code"></app-country-flag>
                              </div>
                              {{ country.nameInGerman }}
                            </mat-option>
                          </mat-select>
                          <mat-error>{{ tCommon('required') }}</mat-error>
                        </mat-form-field>
                      </div>

                      <div fxLayout.gt-xs="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="25px">
                        <mat-form-field fxFlex>
                          <mat-label>{{ t('form.billingEmail') }}</mat-label>
                          <input #billingEmail maxLength="255" matInput formControlName="billingEmail" required />
                          <mat-error>{{ tCommon('enterValid') }}</mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex.gt-xs="175px">
                          <mat-label>{{ t('form.vatId') }}</mat-label>
                          <input
                            #vatId
                            maxLength="255"
                            matInput
                            formControlName="vatId"
                            required
                            mask="AAAAAAAAAAAAAA"
                            [validation]="false"
                          />
                          <mat-error *ngIf="this.form.controls.vatId.errors?.required">{{
                            tCommon('required')
                          }}</mat-error>
                          <mat-error *ngIf="this.form.controls.vatId.errors?.unknownCountry">{{
                            t('error.unknownCountry')
                          }}</mat-error>
                          <mat-error *ngIf="this.form.controls.vatId.errors?.invalidVat">{{
                            t('error.invalidVat')
                          }}</mat-error>
                        </mat-form-field>
                      </div>

                      <div fxLayout.gt-xs="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="25px" fxLayoutAlign="center">
                        <mat-slide-toggle
                          class="centered-content"
                          color="primary"
                          formControlName="smallCompany"
                          (change)="onSmallCompanyChange($event.checked)"
                        >
                          {{ t('form.smallCompanyHint') }}
                        </mat-slide-toggle>
                      </div>
                      <br />
                      <div fxLayout.gt-xs="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="25px">
                        <mat-form-field fxFlex.gt-xs="275px">
                          <mat-label>{{ t('form.orderFrequency.label') }}</mat-label>
                          <mat-select formControlName="orderFrequency" required>
                            <mat-option *ngFor="let orderFrequency of orderFrequencies" [value]="orderFrequency">
                              {{ t('form.orderFrequency.' + orderFrequency) }}
                            </mat-option>
                          </mat-select>
                          <mat-error>{{ tCommon('required') }}</mat-error>
                        </mat-form-field>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="center">
                <button mat-raised-button color="primary" (click)="save()">
                  {{ t('create.save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-footer fxFlex="none"></app-footer>
    </div>
  </ng-container>
</ng-container>
<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
