import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, from as fromPromise, map, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserProfile } from './user-profile';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public isAuthenticated$: Observable<boolean>;
  public userProfile$: Observable<UserProfile | null>;

  constructor(private keycloakService: KeycloakService) {
    this.isAuthenticated$ = fromPromise(this.keycloakService.isLoggedIn());

    this.userProfile$ = this.isAuthenticated$.pipe(
      switchMap(isAuthenticated =>
        isAuthenticated
          ? fromPromise(
              this.keycloakService.loadUserProfile().then(keycloakProfile => {
                return <UserProfile>{
                  title: (keycloakProfile as any).attributes?.title?.[0],
                  firstName: keycloakProfile.firstName,
                  lastName: keycloakProfile.lastName,
                  email: keycloakProfile.email,
                  phone: (keycloakProfile as any).attributes?.phone?.[0],
                  idp: (keycloakProfile as any).attributes?.idp?.[0],
                };
              })
            )
          : of(null)
      )
    );
  }

  public login(): void {
    this.keycloakService.login();
  }

  public logout(redirectUri: string = window.location.origin): void {
    this.keycloakService.logout(redirectUri);
  }

  public register(): void {
    this.keycloakService.register();
  }

  public isUserInRole(role: string): boolean {
    return this.keycloakService.isUserInRole(role);
  }

  public manageAccount(): void {
    this.keycloakService.getKeycloakInstance().accountManagement();
  }

  public getValidToken(): Observable<string | undefined> {
    if (this.keycloakService.isTokenExpired(5)) {
      return from(this.keycloakService.updateToken(5)).pipe(
        map(success => (success ? this.keycloakService.getKeycloakInstance().token : undefined))
      );
    } else {
      return of(this.keycloakService.getKeycloakInstance().token);
    }
  }

  public isLoggedIn(): boolean {
    return !!this.keycloakService.getKeycloakInstance().authenticated;
  }
}
