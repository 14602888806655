import localeDe from '@angular/common/locales/de';
import { Injectable } from '@angular/core';
import { StorageService } from '../../shared/services/storage.service';
import { LocaleOption } from './locale-option';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly currentLocaleKey: string = 'currentLocale';

  private readonly localeOptions: LocaleOption[] = [
    {
      locale: 'de',
      translocoLanguage: 'de',
      angularLocaleModule: localeDe,
    },
    // {
    //   locale: 'en',
    //   translocoLanguage: 'en',
    //   angularLocaleModule: localeEn,
    // },
  ];

  private currentLocale: LocaleOption;

  constructor(private storageService: StorageService) {
    this.currentLocale = this.calcCurrentLocale();
  }

  public getLocaleOptions(): LocaleOption[] {
    return this.localeOptions;
  }

  public getAvailableTranslocoLangs(): string[] {
    return this.localeOptions.map(option => option.translocoLanguage);
  }

  public getLocale(): LocaleOption {
    return this.currentLocale;
  }

  public setLocale(chosenLocale: LocaleOption): void {
    this.storageService.save(this.currentLocaleKey, chosenLocale.locale);
  }

  public deleteCurrentLocale(): void {
    this.storageService.remove(this.currentLocaleKey);
  }

  private calcCurrentLocale(): LocaleOption {
    return this.findStoredLocale() || this.localeFromBrowser() || this.localeOptions[0];
  }

  private findStoredLocale(): LocaleOption | undefined {
    const currentLocale = this.storageService.load(this.currentLocaleKey);
    if (!currentLocale) {
      return undefined;
    }

    return this.localeOptions.find(option => option.locale === currentLocale);
  }

  private localeFromBrowser(): LocaleOption | undefined {
    const browserLocale = navigator.language;
    const browserLanguage = navigator.language.substr(0, 2);
    return (
      this.localeOptions.find(option => option.locale.startsWith(browserLocale)) ||
      this.localeOptions.find(option => option.locale.startsWith(browserLanguage))
    );
  }
}
