import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication-service';
import { environment } from 'src/environments/environment';
import { InvitedDlgComponent } from '../../customer/users/invited-dlg/invited-dlg.component';
import { UsersService } from '../../customer/users/users.service';
import { Customer } from '../model/customer';
import { SolvencyStatus } from './solvency-status';

@Injectable({
  providedIn: 'root',
})
export class UserCustomerService {
  private customerUrl: string = environment.backend.url + '/customers';

  public readonly customer$ = new ReplaySubject<Customer | null>(1);

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private usersService: UsersService,
    private dialog: MatDialog
  ) {
    this.authenticationService.isAuthenticated$
      .pipe(
        distinctUntilChanged(),
        switchMap(isAuthenticated => (isAuthenticated ? this.loadCurrentUserCustomer() : of(null)))
      )
      .subscribe(customer => {
        this.customer$.next(customer);
      });
  }

  public subscribeInvitationCheck(): void {
    this.customer$.subscribe(customer => {
      this.checkInvitation(customer);
    });
  }

  private checkInvitation(currentCustomer: Customer | null): void {
    this.authenticationService.userProfile$.subscribe(user => {
      if (!!user) {
        this.usersService.getInvitedToCustomer().subscribe(invitedToCustomer => {
          if (invitedToCustomer) {
            this.dialog.open(InvitedDlgComponent, {
              disableClose: true,
              data: { invitedToCustomer: invitedToCustomer, currentCustomer: currentCustomer },
            });
          }
        });
      }
    });
  }

  public setCustomer(customer: Customer): void {
    this.customer$.next(customer);
  }

  private loadCurrentUserCustomer(): Observable<Customer | null> {
    return this.http.get<Customer | null>(this.customerUrl);
  }

  public getSolvencyStatusOfCurrentCustomer(): Observable<SolvencyStatus> {
    return this.http.get<SolvencyStatus>(`${this.customerUrl}/solvencyStatus`);
  }

  public sendDirectDebitApplication(applicationFormInput: any): Observable<void> {
    return this.http.put<void>(`${this.customerUrl}/direct-debit-application`, applicationFormInput);
  }
}
