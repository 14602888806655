import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Country } from '../model/country';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private url = `${environment.backend.url}/public/countries`;

  constructor(private http: HttpClient) {}

  /**
   * Returns all selectable public countries.
   */
  public getPublicCountries(): Observable<Country[]> {
    return this.http
      .get<Country[]>(`${this.url}`)
      .pipe(
        map(countries =>
          countries.sort((a: Country, b: Country): number => a.nameInGerman.localeCompare(b.nameInGerman))
        )
      );
  }
}
