import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  public trackPurchaseForMeta(value: number, currency: string = 'EUR'): void {
    if (window && (window as any).fbq) {
      (window as any).fbq('track', 'Purchase', {
        currency: currency,
        value: value,
      });
    } else {
      console.warn('Meta Pixel (fbq) is not loaded.');
    }
  }

  public trackCompleteRegistrationForMeta(): void {
    if (window && (window as any).fbq) {
      (window as any).fbq('track', 'CompleteRegistration');
    } else {
      console.warn('Meta Pixel (fbq) is not loaded.');
    }
  }

  public reportRevenueForBing(revenueValue: number, currency: string = 'EUR'): void {
    if (window && window['reportRevenue_bingConversionTracking']) {
      window['reportRevenue_bingConversionTracking'](revenueValue, currency);
    }
  }
}
