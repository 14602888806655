import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Pipe({
  name: 'dateRange',
})
export class DateRangePipe implements PipeTransform {
  public transform(value: { minDays: number; maxDays: number }, ...args: unknown[]): string {
    const text = value.minDays === value.maxDays ? value.minDays.toString() : `${value.minDays} - ${value.maxDays}`;
    return translate('common.days', { text, count: value.maxDays });
  }
}
