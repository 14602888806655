import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthGuard } from 'src/app/core/authentication/auth-guard';
import { UserCustomerService } from './user-customer.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerAuthGuard extends AuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private userCustomerService: UserCustomerService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const superResult = await super.isAccessAllowed(route, state);

    if (superResult !== true) {
      return superResult;
    }

    const customer = await lastValueFrom(this.userCustomerService.customer$.pipe(take(1)));
    if (customer !== null && (customer.vatId || customer.smallCompany)) {
      return true;
    } else {
      return this.router.createUrlTree(['/register-customer'], { queryParams: { redirectTo: state.url } });
    }
  }
}
