import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/authentication/auth-guard';
import { Roles } from './core/authentication/roles';
import { CustomerAuthGuard } from './shared/customer/customer-auth-guard';
import { CustomerRegistrationComponent } from './shared/customer/customer-registration/customer-registration.component';
import { CepraAuthGuard } from './core/authentication/cepra-auth-guard';
import { AccountComponent } from './account/account.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./shop/shop.module').then(i => i.ShopModule),
    canActivate: [CepraAuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(i => i.AdminModule),
    canActivate: [CepraAuthGuard, AuthGuard],
    data: { roles: [Roles.Admin] },
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer/customer.module').then(i => i.CustomerModule),
    canActivate: [CepraAuthGuard, CustomerAuthGuard],
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [CepraAuthGuard, CustomerAuthGuard],
  },
  {
    path: 'register-customer',
    component: CustomerRegistrationComponent,
    canActivate: [CepraAuthGuard, AuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
