import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styleUrls: ['./chips-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsAutocompleteComponent),
      multi: true,
    },
  ],
})
export class ChipsAutocompleteComponent<T, ID> implements OnInit, ControlValueAccessor {
  @Input() public chipTemplate!: TemplateRef<any>;
  @Input() public optionsTemplate!: TemplateRef<any>;

  @Input() public labelText = '';
  @Input() public newItemPlaceholder = 'Neu';
  @Input() public options$: Observable<T[]> = of([]);
  @Input() public required = false;
  @Input() public filterFunc: (searchText: string, option: T) => boolean = () => true;
  @Input() public identifierFunc!: (option: T) => ID;

  public itemControl = new UntypedFormControl();
  public chipsControl = new UntypedFormControl();

  public filteredOptions$: Subject<T[]> = new Subject();
  public currentItems$: Subject<T[]> = new Subject();

  @ViewChild('newItemInput') public newItemInput!: ElementRef<HTMLInputElement>;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (newValues: ID[]) => void = () => {};

  public ngOnInit(): void {
    const sharedOptions = this.options$.pipe(shareReplay(1));

    this.chipsControl = new UntypedFormControl([], this.required ? Validators.required : null);
    this.chipsControl.valueChanges.pipe(untilDestroyed(this)).subscribe((newIds: ID[]) => {
      this.onChange(newIds);
    });

    combineLatest([
      sharedOptions,
      this.itemControl.valueChanges.pipe(
        startWith(''),
        filter(value => typeof value === 'string')
      ),
    ])
      .pipe(
        map(([allOptions, searchText]) => this.filterOptions(searchText, allOptions)),
        untilDestroyed(this)
      )
      .subscribe(filteredOptions => this.filteredOptions$.next(filteredOptions));

    combineLatest([sharedOptions, this.chipsControl.valueChanges])
      .pipe(
        map(([allOptions, currentId]) => this.findSuitableOptions(currentId, allOptions)),
        untilDestroyed(this)
      )
      .subscribe(currentItems => this.currentItems$.next(currentItems));
  }

  public remove(item: T): void {
    const id = this.identifierFunc(item);
    const index = this.currentIds.indexOf(id);

    if (index >= 0) {
      this.currentIds.splice(index, 1);
      if (this.currentIds.length === 0) {
        this.currentIds = [];
      } else {
        this.chipsControl.updateValueAndValidity();
      }
    }
  }

  public get currentIds(): ID[] {
    return this.chipsControl.value;
  }

  public set currentIds(newValue: ID[]) {
    this.chipsControl.setValue(newValue);
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    const item = event.option.value;
    const id = this.identifierFunc(item);
    this.currentIds = [...this.currentIds, id];

    this.newItemInput.nativeElement.value = '';
    this.itemControl.setValue('');
  }

  private getUnusedOptions(allOptions: T[]): T[] {
    return allOptions.filter(option => {
      const idOfOptionInQuestion = this.identifierFunc(option);
      return !this.currentIds.some(currentId => currentId === idOfOptionInQuestion);
    });
  }

  private filterOptions(searchText: string, allOptions: T[]): T[] {
    const unusedOptions = this.getUnusedOptions(allOptions);
    return unusedOptions.filter(option => this.filterFunc(searchText, option));
  }

  public registerOnTouched(fn: any): void {
    // not implemented
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public writeValue(newIds: ID[]): void {
    this.currentIds = newIds;
  }

  public onBlur(): void {
    // clean new item control when blurring
    this.newItemInput.nativeElement.value = '';
  }

  public onFocus(): void {
    // force reload of filtered options
    this.itemControl.setValue('');
  }

  private findSuitableOptions(currentIds: ID[], allOptions: T[]): T[] {
    return allOptions.filter(option => currentIds.includes(this.identifierFunc(option)));
  }
}
