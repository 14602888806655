import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { ChatMessage, ChatMessageType } from '../chat-message';

@UntilDestroy()
@Component({
  selector: 'app-chat-conversation',
  templateUrl: './chat-conversation.component.html',
  styleUrls: ['./chat-conversation.component.scss'],
})
export class ChatConversationComponent implements OnInit, AfterViewInit {
  @ViewChildren('scrollMe')
  private myScrollContainer!: QueryList<ElementRef>;

  @ViewChild('scrollWrapper')
  private myScrollWrapper!: ElementRef;

  @Input()
  public messageHistory$?: Observable<ChatMessage[]>;

  @Input()
  public ownRole: ChatMessageType = ChatMessageType.QUESTION;

  @Input()
  public newMessages$?: Observable<ChatMessage[]>;

  @Input()
  public canAddMessages: boolean = true;

  @Input()
  public isCustomer: boolean = false;

  @Output()
  public handleMessageSent: EventEmitter<ChatMessage> = new EventEmitter<ChatMessage>();

  public messageControl: FormControl;
  public messages: ChatMessage[] = [];

  constructor(private formBuilder: FormBuilder) {
    this.messageControl = formBuilder.control('');
  }

  public ngOnInit(): void {
    if (!!this.messageHistory$) {
      this.messageHistory$.subscribe(history => {
        this.messages = [...history];
      });
    }
    if (!!this.newMessages$) {
      this.newMessages$.subscribe(cm => this.handleNewMessageFromPartner(cm));
    }
  }

  public ngAfterViewInit(): void {
    this.scrollToBottom();
    this.myScrollContainer.changes.subscribe(() => {
      this.scrollToBottom(); // For messages added later
    });
  }

  private scrollToBottom(): void {
    try {
      this.myScrollWrapper.nativeElement.scrollTop = this.myScrollWrapper.nativeElement.scrollHeight;
    } catch (err) {}
  }

  public sendMessage(): void {
    const text = this.messageControl.value;
    if (!text || text === '') {
      return;
    }
    this.addMessage(text);
  }

  private addMessage(messageText: string): void {
    const chatMessage = new ChatMessage(DateTime.now(), messageText, this.ownRole);
    this.messages.push(chatMessage);
    this.handleMessageSent.emit(chatMessage);

    this.messageControl.reset();
  }

  private handleNewMessageFromPartner(chatMessages: ChatMessage[]): void {
    chatMessages.forEach(cm => this.messages.push(cm));
  }

  public isOwnMessage(message: ChatMessage): boolean {
    return message.messageType === this.ownRole;
  }

  public isPersonalAssistanceAvailable(): boolean {
    return this.ownRole === ChatMessageType.QUESTION && this.messages.length > 1;
  }

  public handleReactionClick(reactionText: string): void {
    this.addMessage(reactionText);
  }

  public isLastMessage(message: ChatMessage): boolean {
    return this.messages[this.messages.length - 1] === message;
  }
}
