import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export interface IconSnackbarData {
  icon: string;
  message: string;
  additionalActions?: IconSnackbarAction[];
}

export interface IconSnackbarAction {
  label: string;
  onClick: () => void;
}

@Component({
  selector: 'app-icon-snackbar',
  templateUrl: './icon-snackbar.component.html',
  styleUrls: ['./icon-snackbar.component.scss'],
})
export class IconSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: IconSnackbarData,
    private snackBarRef: MatSnackBarRef<IconSnackbarComponent>
  ) {}

  public dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
