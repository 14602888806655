import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeleteAccountService {
  private usersUrl = environment.backend.url + '/users';
  constructor(private http: HttpClient) {}

  public deleteAccount(email?: string): Observable<void> {
    return this.http.delete<void>(`${this.usersUrl}/${email}`);
  }
}
