import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    Trustpilot: any;
  }
}

@Component({
  selector: 'app-trustpilot-widget',
  templateUrl: './trustpilot-widget.component.html',
  styleUrls: ['./trustpilot-widget.component.scss'],
})
export class TrustpilotWidgetComponent implements OnInit {
  public trustpilotReviewUrl: string = environment.trustpilot.reviewUrl;

  public businessUnitID = environment.trustpilot.businessUnitID;

  constructor() {}

  public ngOnInit(): void {
    const trustboxRef = document.getElementById('trustbox');
    trustboxRef?.setAttribute('data-businessunit-id', this.businessUnitID);
    window.Trustpilot.loadFromElement(trustboxRef);
  }
}
