import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { translate } from '@ngneat/transloco';
import { UIService } from 'src/app/shared/services/ui.service';
import { UserCustomerService } from '../../../shared/customer/user-customer.service';
import { Customer } from '../../../shared/model/customer';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-invited-dlg',
  templateUrl: './invited-dlg.component.html',
  styleUrls: ['./invited-dlg.component.scss'],
})
export class InvitedDlgComponent implements OnInit {
  public invitedToCustomer: Customer;
  public currentCustomer: Customer | null;

  public isLoading = true;

  public canMigrateCustomerData = false;

  public shouldMigrateCustomerData = new UntypedFormControl(true);

  constructor(
    private dialogRef: MatDialogRef<InvitedDlgComponent>,
    private userService: UsersService,
    private userCustomerService: UserCustomerService,
    private uiService: UIService,
    @Inject(MAT_DIALOG_DATA) public data: { invitedToCustomer: Customer; currentCustomer: Customer | null }
  ) {
    this.invitedToCustomer = data.invitedToCustomer;
    this.currentCustomer = data.currentCustomer;
  }

  public ngOnInit(): void {
    if (this.currentCustomer) {
      this.userService.canMigrateCustomerData().subscribe({
        next: canMigrateCustomerData => (this.canMigrateCustomerData = canMigrateCustomerData),
        complete: () => (this.isLoading = false),
      });
    } else {
      this.isLoading = false;
    }
  }

  public acceptInvitation(): void {
    this.userService
      .acceptInvitation(this.canMigrateCustomerData && this.shouldMigrateCustomerData.value)
      .subscribe(() => {
        this.userCustomerService.setCustomer(this.invitedToCustomer);
        this.uiService.showSuccessMessage(
          translate(
            this.canMigrateCustomerData
              ? this.shouldMigrateCustomerData.value
                ? 'customer.users.invitedDialog.successfullyAcceptedAndMigratedData'
                : 'customer.users.invitedDialog.successfullyAcceptedAndNotMigratedData'
              : 'customer.users.invitedDialog.successfullyAccepted',
            { company: this.invitedToCustomer.company }
          )
        );
        this.dialogRef.close(true);
      });
  }

  public rejectInvitation(): void {
    this.userService.rejectInvitation().subscribe(() => {
      this.uiService.showSuccessMessage(translate('customer.users.invitedDialog.successfullyRejected'));
      this.dialogRef.close(false);
    });
  }
}
