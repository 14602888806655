export const AllowedLandTransportCountries = new Map([
  ['DE', 'Deutschland'],
  ['AT', 'Österreich'],
  ['FR', 'Frankreich'],
  ['NL', 'Niederlande'],
  ['CH', 'Schweiz'],
  ['PL', 'Polen'], 
  ['CZ', 'Tschechien'],
  ['DK', 'Dänemark'],
  ['BE', 'Belgien'],
  ['ES', 'Spanien'],
  ['GB', 'Großbritannien'],
  ['SE', 'Schweden'],
  ['IT', 'Italien'],
  ['IE', 'Irland'],
  ['HU', 'Ungarn'],
  ['RO', 'Rumänien'],
  ['FI', 'Finnland'],
  ['SK', 'Slowakei'],
  ['PT', 'Portugal'],
  ['NO', 'Norwegen'],
  ['LT', 'Litauen'],
  ['LU', 'Luxemburg'],
  ['SI', 'Slowenien'],
  ['BG', 'Bulgarien'],
  ['GR', 'Griechenland'],
  ['LV', 'Lettland'],
  ['HR', 'Kroatien'],
  ['RS', 'Serbien'],
  ['EE', 'Estland'],
  ['TR', 'Türkei'],
  ['TN', 'Tunesien'],
  ['MA', 'Marokko'],
  ['LI', 'Liechtenstein'],
  ['CY', 'Zypern'],
  ['SM', 'San Marino'],
  ['MK', 'Mazedonien'],
  ['UA', 'Ukraine'],
  ['IS', 'Island'],
  ['MD', 'Republik Moldau'],
  ['AL', 'Albanien'],
  ['GE', 'Georgien'],
  ['BA', 'Bosnien-Herzegowina'],
  ['AD', 'Andorra']
]);