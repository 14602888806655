export const CountriesWithoutZipcodeRules = [
  'AO',
  'AG',
  'GQ',
  'BS',
  'BZ',
  'BJ',
  'BO',
  'BW',
  'BF',
  'BI',
  'CD',
  'DM',
  'DJ',
  'CI',
  'ER',
  'FJ',
  'GA',
  'GM',
  'GD',
  'GY',
  'HK',
  'YE',
  'CM',
  'QA',
  'KI',
  'KM',
  'MW',
  'ML',
  'MR',
  'NA',
  'NR',
  'KP',
  'TL',
  'CG',
  'RW',
  'SB',
  'ST',
  'SC',
  'SL',
  'ZW',
  'SO',
  'KN',
  'LC',
  'SR',
  'SY',
  'TG',
  'TO',
  'TT',
  'TD',
  'TV',
  'UG',
  'VU',
  'AE',
  'CF',
];
