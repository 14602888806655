// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  keycloakConfig: {
    url: 'https://koch-logistikshop.dev.lmis.de/auth',
    realm: 'Koch',
    clientId: 'logistikshop',
  },
  chatEnabled: false,
  backend: {
    url: 'https://koch-logistikshop.dev.lmis.de/api',
    chatUrl: 'wss://koch-logistikshop.dev.lmis.de/api/ws',
  },
  gaTrackingId: '', // Must be defined in environment.json, will not work if only defined here.
  crefoSmartSignup: {
    url: 'https://sandbox.crefopay.de/autocomplete/',
    itemCount: 5,
    publicKey: '6e945582a1e342634bd5e6f837359ee85817746a267bea28d05c6515bd6f0996',
  },
  trustpilot: {
    businessUnitID: '5eac0bce597c0b00019d0951',
    reviewUrl: 'https://de.trustpilot.com/review/koch-international.de',
  },
  kochInternationalWebsite: {
    url: 'https://www.koch-international.de/',
    projectsUrl: 'https://www.koch-international.de/umweltprojekte/',
    conditionsUrl: 'https://www.koch-international.de/agb/',
    imprintUrl: 'https://www.koch-international.de/impressum/',
    privacyUrl: 'https://www.koch-international.de/datenschutz/',
    customersRecruitCustomersConditionsUrl: 'https://koch-international.de/kunden-werben-kunden/',
  },
  enableHotjar: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
