import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'RelativeDateWithTimePipe',
})
export class RelativeDateWithTimePipe implements PipeTransform {
  public transform(value?: DateTime, ...args: unknown[]): string {
    if (!value) {
      return '';
    }

    const dateNow = DateTime.now().startOf('day');
    const dateValue = value.startOf('day');
    const dayDiff = dateNow.diff(dateValue, 'days').as('days');

    const dayStr = dayDiff <= 3 ? value.toRelativeCalendar() : value.toFormat('D');
    const timeStr = value.toFormat('t');

    return dayStr + ', ' + timeStr;
  }
}
