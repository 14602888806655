import { DateTime } from 'luxon';

export class ChatMessage {
  public time: DateTime;
  public text: string;
  public messageType: ChatMessageType;
  public proposedReactions: string[];

  constructor(time: DateTime, text: string, messageType: ChatMessageType) {
    this.time = time;
    this.text = text;
    this.messageType = messageType;
    this.proposedReactions = [];
  }
}

export enum ChatMessageType {
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER',
}
