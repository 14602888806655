import { Injectable } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { translate } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../components/confirm-dialog/confirm-dialog.component';
import {
  IconSnackbarAction,
  IconSnackbarComponent,
  IconSnackbarData,
} from '../components/icon-snackbar/icon-snackbar.component';

export interface ConfirmDialogOptions {
  confirmButtonColor: ThemePalette;
  buttonLabels: 'confirm' | 'yesno';
  confirmationText?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UIService {
  private readonly messageVisibilityDuration = 10_000;

  constructor(private dialog: MatDialog, private matSnackBar: MatSnackBar) {}

  public showSuccessMessage(message?: string, additionalActions?: IconSnackbarAction[]): Observable<void> {
    const messageText = message || translate('common.successMessage') || 'common.successMessage';
    return this.showMessage({ message: messageText, icon: 'done', additionalActions }, 'success-message');
  }

  public showErrorMessage(message?: string, additionalActions?: IconSnackbarAction[]): Observable<void> {
    const messageText = message || translate('common.errorMessage') || 'common.errorMessage';
    return this.showMessage({ message: messageText, icon: 'cancel', additionalActions }, 'error-message');
  }

  public showInfoMessage(message: string, additionalActions?: IconSnackbarAction[]): Observable<void> {
    return this.showMessage({ message, icon: 'info', additionalActions }, 'info-message');
  }

  public showMessage(snackbarData: IconSnackbarData, panelClass: string | string[]): Observable<void> {
    const snackBar = this.matSnackBar.openFromComponent(IconSnackbarComponent, {
      horizontalPosition: 'end',
      duration: this.messageVisibilityDuration,
      verticalPosition: 'bottom',
      panelClass,
      data: snackbarData,
    } as MatSnackBarConfig<IconSnackbarData>);
    return snackBar.afterDismissed().pipe(map(() => {}));
  }

  public confirm(title: string, message: string, options?: ConfirmDialogOptions): Observable<boolean> {
    if (!options) {
      options = { buttonLabels: 'yesno', confirmButtonColor: 'primary' };
    }

    const dialogData = new ConfirmDialogModel(title, message, options);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    return dialogRef.afterClosed();
  }

  public confirmDiscardingUnsavedChanges(): Observable<boolean> {
    return this.confirm(translate('common.unsavedChanges.headline'), translate('common.unsavedChanges.question'));
  }
}
