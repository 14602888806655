<div class="chat-frame" fxLayout="column" *transloco="let t; read: 'chat'">
  <div class="message-history" fxLayout="column" fxFlex="0 0 calc(100% - 3em)" #scrollWrapper>
    <ng-container *ngFor="let message of messages" #scrollMe>
      <div class="own-message triangle-right" *ngIf="isOwnMessage(message)">{{ message.text }}</div>
      <div class="partner-message triangle-left" *ngIf="!isOwnMessage(message)">
        <span [innerHTML]="message.text | linky"></span>
        <div *ngIf="isLastMessage(message)">
          <button
            class="reaction-button"
            *ngFor="let reaction of message.proposedReactions"
            (click)="handleReactionClick(reaction)"
          >
            {{ reaction }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="new-message-line" fxLayout="row" fxFlex="0 0 3em" *ngIf="canAddMessages">
    <input
      class="message-input"
      matInput
      [formControl]="messageControl"
      fxFlex="grow"
      (keydown.enter)="sendMessage()"
      placeholder="{{ t('yourMessage') }}"
    />
    <button mat-button (click)="sendMessage()" matTooltip="{{ t('sendMessage') }}"><mat-icon>send</mat-icon></button>
  </div>
</div>
