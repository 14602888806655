import { Component, OnInit } from '@angular/core';
import { DeleteAccountService } from './delete-account.service';
import { AuthenticationService } from '../../../core/authentication/authentication-service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {
  public static readonly PATH = 'delete-account';
  private email?: string;

  constructor(private deleteAccountService: DeleteAccountService, private auth: AuthenticationService) {}

  public ngOnInit(): void {
    this.auth.userProfile$.subscribe(p => (this.email = p?.email));
  }

  public deleteAccount(): void {
    this.deleteAccountService.deleteAccount(this.email).subscribe(() => {
      this.auth.logout();
    });
  }
}
