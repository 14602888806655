<div *transloco="let t; read: 'customer.deleteAccount'" class="content">
  <mat-card class="delete-account-card">
    <mat-card-header>
      <h1>{{ t('title') }}</h1>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutGap="15px" fxFlex="grow">
        <p>{{ t('confirmationText') }}</p>
        <button class="delete-account-button" mat-raised-button color="warn" (click)="deleteAccount()">
          {{ t('deleteButton') }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
