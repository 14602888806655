import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { AuthenticationService } from '../core/authentication/authentication-service';
import { UsersService } from '../customer/users/users.service';
import { DeleteAccountComponent } from '../shared/components/delete-account/delete-account.component';
import { UIService } from '../shared/services/ui.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  public userProfile$ = this.authenticationService.userProfile$;
  public isLoading = true;

  public isSubscribedControl!: FormControl;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private uiService: UIService,
    private authenticationService: AuthenticationService
  ) {}

  public ngOnInit(): void {
    this.isSubscribedControl = new FormControl();

    this.usersService.isSubscribedForMarketingMails().subscribe({
      next: isSubscribed => {
        this.isSubscribedControl.setValue(isSubscribed);

        this.isSubscribedControl.valueChanges.subscribe(newValue => {
          this.isLoading = true;
          this.usersService.setSubsciptionValueForMarketingMails(newValue).subscribe({
            next: () => this.uiService.showSuccessMessage(translate('common.successMessage')),
            complete: () => {
              this.isLoading = false;
            },
            error: () => {
              this.isLoading = false;
              this.uiService.showErrorMessage(translate('common.errorMessage'));
            },
          });
        });
      },
      complete: () => (this.isLoading = false),
    });
  }

  public updateProfile(): void {
    this.usersService.updateProfile().subscribe({
      next: () => this.uiService.showSuccessMessage(translate('account.successMessage')),
      error: () => this.uiService.showErrorMessage(translate('account.errorMessage')),
    });
  }

  public updatePassword(): void {
    this.usersService.updatePassword().subscribe({
      next: () => this.uiService.showSuccessMessage(translate('account.successMessage')),
      error: () => this.uiService.showErrorMessage(translate('account.errorMessage')),
    });
  }

  public deleteAccount(): void {
    this.router.navigateByUrl(DeleteAccountComponent.PATH);
  }
}
