import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsAdminDirective } from './authentication/is-admin.directive';
import { IsAuthenticatedDirective } from './authentication/is-authenticated.directive';
import { NotAuthenticatedDirective } from './authentication/not-authenticated.directive';

@NgModule({
  declarations: [IsAuthenticatedDirective, NotAuthenticatedDirective, IsAdminDirective],
  imports: [CommonModule],
  exports: [IsAuthenticatedDirective, NotAuthenticatedDirective, IsAdminDirective],
})
export class CoreModule {}
