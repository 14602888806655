import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { translate } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, switchMap, tap } from 'rxjs';
import { Title } from '../../model/title';
import { UIService } from '../../services/ui.service';
import { UserCustomerService } from '../user-customer.service';

@UntilDestroy()
@Component({
  selector: 'app-direct-debit-application',
  templateUrl: './direct-debit-application.component.html',
  styleUrls: ['./direct-debit-application.component.scss'],
})
export class DirectDebitApplicationComponent {
  public title = Title;
  public isLoading = false;

  public formGroup: FormGroup = this.formBuilder.group({
    title: [, [Validators.required]],
    firstName: [, [Validators.required]],
    lastName: [, [Validators.required]],
    street: [, [Validators.required]],
    houseNumber: [, [Validators.required]],
    zipCode: [, [Validators.required]],
    city: [, [Validators.required]],
    email: [, [Validators.required, Validators.email]],
    bank: [, [Validators.required]],
    iban: [, [Validators.required, Validators.pattern('\\w{22}')]],
    bic: [, [Validators.required, Validators.pattern('\\w{8,11}')]],
    authorizationAccepted: [false, [Validators.requiredTrue]],
  });

  constructor(
    private dialogRef: MatDialogRef<DirectDebitApplicationComponent>,
    private formBuilder: FormBuilder,
    private uiService: UIService,
    private userCustomerService: UserCustomerService
  ) {}

  public sendApplication(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.uiService
      .confirm(
        translate('customer.directDebitApplication.confirmDialog.title'),
        translate('customer.directDebitApplication.confirmDialog.message')
      )
      .pipe(
        filter(confirmed => confirmed),
        tap(() => (this.isLoading = true)),
        switchMap(() => this.userCustomerService.sendDirectDebitApplication(this.formGroup.value)),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          this.uiService.showSuccessMessage(translate('customer.directDebitApplication.successfullySent'));
          this.dialogRef.close(true);
        },
        complete: () => (this.isLoading = false),
      });
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }
}
