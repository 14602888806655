<button class="user-circle-button" mat-raised-button color="accent" [matMenuTriggerFor]="userMenu">
  <mat-icon>account_circle</mat-icon>
</button>
<mat-menu #userMenu="matMenu">
  <div class="user-info" fxLayout="column" fxLayoutGap="4px" *ngIf="userProfile$ | async as userProfile">
    <div class="user-name">{{ userProfile.firstName }} {{ userProfile.lastName }}</div>
    <div class="user-email">{{ userProfile.email }}</div>
  </div>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="editProfile()">
    <mat-icon>manage_accounts</mat-icon><span>{{ 'common.user.profile' | transloco }}</span>
  </button>
  <ng-container *appUserIsAdmin>
    <mat-divider></mat-divider>
    <button mat-menu-item routerLink="/admin">
      <mat-icon>admin_panel_settings</mat-icon><span>{{ 'common.user.administration' | transloco }}</span>
    </button>
  </ng-container>
  <mat-divider></mat-divider>
  <button mat-menu-item routerLink="/customer" fxShow fxHide.lt-md>
    <mat-icon>business</mat-icon><span>{{ 'common.user.customer' | transloco }}</span>
  </button>
  <button mat-menu-item (click)="showAccessDeniedHint()" fxHide fxShow.lt-md>
    <mat-icon>business</mat-icon><span>{{ 'common.user.customer' | transloco }}</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon><span>{{ 'common.user.logout' | transloco }}</span>
  </button>
</mat-menu>
