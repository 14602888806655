import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication-service';
import { InvitedDlgComponent } from 'src/app/customer/users/invited-dlg/invited-dlg.component';
import { UsersService } from 'src/app/customer/users/users.service';
import { TrackingService } from 'src/app/shop/order/tracking.service';
import { $enum } from 'ts-enum-util';
import { environment } from '../../../../environments/environment';
import { Country } from '../../model/country';
import { Customer } from '../../model/customer';
import { OrderFrequency } from '../../model/order-frequency';
import { CountryService } from '../../services/country.service';
import { CustomValidators } from '../../validation/custom-validators';
import { UserCustomerService } from '../user-customer.service';
import { CustomerRegistrationService } from './customer-registration.service';

@UntilDestroy()
@Component({
  selector: 'app-customer-registration',
  templateUrl: './customer-registration.component.html',
  styleUrls: ['./customer-registration.component.scss'],
})
export class CustomerRegistrationComponent implements OnInit, AfterViewInit {
  public isLoading = true;

  public countries: Country[] = [];
  public orderFrequencies = $enum(OrderFrequency).getValues();

  public form: FormGroup = this.fb.group({
    company: [null, [Validators.required]],
    street: [null, [Validators.required]],
    houseNumber: [null, [Validators.required]],
    zipCode: [null, [Validators.required]],
    city: [null, [Validators.required]],
    countryCode: ['DE', [Validators.required]],
    addressInfo: [null],
    vatId: [null, [Validators.required, CustomValidators.validVat]],
    billingEmail: [null, [Validators.required, Validators.email]],
    smallCompany: [false, [Validators.required]],
    orderFrequency: [null, [Validators.required]],
  });
  public redirectUrl: string | null = null;

  public customer: Customer | null = null;

  public get isNewCustomer(): boolean {
    return this.customer === null;
  }

  @ViewChildren('countryRef')
  private countryFormDirective!: QueryList<ElementRef>;

  @ViewChild('street')
  private streetFormDirective!: ElementRef;
  @ViewChild('houseNumber')
  private houseNumberFormDirective!: ElementRef;
  @ViewChild('city')
  private cityFormDirective!: ElementRef;
  @ViewChild('zipCode')
  private zipCodeFormDirective!: ElementRef;

  constructor(
    private customerRegistrationService: CustomerRegistrationService,
    private userCustomerService: UserCustomerService,
    private authenticationService: AuthenticationService,
    private usersService: UsersService,
    private countryService: CountryService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private trackingService: TrackingService
  ) {}

  public ngOnInit(): void {
    forkJoin([
      this.route.queryParamMap.pipe(
        map(paramMap => paramMap.get('redirectTo')),
        take(1)
      ),
      this.userCustomerService.customer$.pipe(take(1)),
      this.countryService.getPublicCountries(),
      this.authenticationService.userProfile$.pipe(take(1)),
    ]).subscribe({
      next: ([redirectTo, customer, countries, user]) => {
        this.redirectUrl = redirectTo;
        this.customer = customer;
        this.countries = countries;

        if (!!user && this.isNewCustomer) {
          this.usersService.getInvitedToCustomer().subscribe(invitedToCustomer => {
            if (invitedToCustomer) {
              this.dialog
                .open(InvitedDlgComponent, {
                  disableClose: true,
                  data: { invitedToCustomer: invitedToCustomer },
                })
                .afterClosed()
                .subscribe(result => {
                  if (result && this.redirectUrl) {
                    this.customer = invitedToCustomer;
                    this.init();
                  }
                });
            }
          });
        }

        this.init();
      },
      complete: () => (this.isLoading = false),
    });
  }

  public ngAfterViewInit(): void {
    this.configureSmartSignup();
    this.configureInputHooks();
  }

  public configureSmartSignup(): void {
    const configuration = {
      url: environment.crefoSmartSignup.url,
      itemCount: environment.crefoSmartSignup.itemCount,
      suggestion: { city: true, country: true, zip: true },
    };

    // @ts-ignore
    new SmartSignUpClient(environment.crefoSmartSignup.publicKey, function () {}, configuration);
  }

  /**
   * Dirty workaround for Angular's reactive forms not recognizing changes made by JQuery's autocomplete widget.
   *
   * The view has a hidden input field for the country, since the autocomplete cannot complete into a mat-select.
   * The main view isn't immediately initialized because the entire container is wrapped in a *ngIf checking if it's
   * still loading. This will cause @ViewChild to return undefined during ngAfterViewInit. The "ViewChildren" directive
   * lets us subscribe to changes and can recognize once a ViewChild is rendered.
   *
   * Once the ViewChild is rendered we subscribe to changes on the hidden Input-element. Every change on that element
   * means that an autocomplete element has been selected. We then use the values in the input fields and patch the value of
   * the form
   *
   */
  private configureInputHooks(): void {
    this.countryFormDirective.changes.subscribe((comps: QueryList<ElementRef>) => {
      (comps.first.nativeElement as HTMLInputElement).onchange = () => {
        setTimeout(() => {
          this.form.get('city')?.patchValue(this.cityFormDirective.nativeElement.value);
          this.form.get('zipCode')?.patchValue(this.zipCodeFormDirective.nativeElement.value);
          this.form.get('countryCode')?.patchValue(comps.first.nativeElement.value);
          this.form.get('street')?.patchValue(this.streetFormDirective.nativeElement.value);
          this.form.get('houseNumber')?.patchValue(this.houseNumberFormDirective.nativeElement.value);
        }, 50);
      };
    });
  }

  private init(): void {
    if (this.customer) {
      if (this.customer.vatId || this.customer.smallCompany) {
        this.navigateToRedirectUrl();
        return;
      }

      this.form.patchValue(this.customer);
      this.onSmallCompanyChange(this.customer.smallCompany);

      if (!this.customer.customerCanChange) {
        this.form.disable();
        this.form.controls.vatId.enable();
      }
    }
  }

  public onSmallCompanyChange(checked: boolean): void {
    if (checked) {
      if (!this.isNewCustomer) this.customer!.vatId = undefined;
      this.form.controls.vatId.patchValue(null);
      this.form.controls.vatId.markAsTouched();
      this.form.controls.vatId.disable();
    } else {
      this.form.controls.vatId.enable();
      this.form.controls.vatId.markAsTouched();
    }
  }

  public save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const customer = { ...this.customer, ...this.form.value } as Customer;
    var selectedCountry = this.countries.find(c => c.code === customer.countryCode)!;
    customer.country = selectedCountry.nameInGerman;
    this.isLoading = true;

    const saveAction = this.isNewCustomer
      ? this.customerRegistrationService.saveNewCustomer(customer)
      : this.customerRegistrationService.saveMissingCustomerData(customer);

    saveAction.subscribe({
      next: savedCustomer => {
        this.userCustomerService.setCustomer(savedCustomer);
        this.navigateToRedirectUrl();
        this.trackingService.trackCompleteRegistrationForMeta();
      },
      complete: () => (this.isLoading = false),
    });
  }

  private navigateToRedirectUrl(): void {
    this.router.navigateByUrl(this.redirectUrl || '/');
  }
}
