import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrustpilotWidgetSettingsService {
  private readonly URL = environment.backend.url + '/public/trustpilot-widget-settings';

  constructor(private http: HttpClient) {}

  public getTrustpilotWidgetSettings(): Observable<boolean> {
    return this.http.get<any>(`${this.URL}/visibility`);
  }
}
