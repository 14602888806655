import { Component } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { AuthenticationService } from 'src/app/core/authentication/authentication-service';
import { UIService } from '../../services/ui.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent {
  public userProfile$ = this.authenticationService.userProfile$;

  constructor(private authenticationService: AuthenticationService,
              private uiService: UIService,
              private router: Router) {}

  public logout(): void {
    this.authenticationService.logout();
  }

  public editProfile(): void {
    this.router.navigateByUrl('account');
  }

  public showAccessDeniedHint(): void {
    this.uiService.showInfoMessage(translate('common.user.customerAccessDenied'));
  }
}
